<div class="gxD:py-12 flex w-full flex-col gap-4 rounded-lg bg-white py-6 cgxT:gap-6 cgxT:py-6 cgxD:gap-12">
  <div class="flex flex-col gap-4 cgxT:gap-6 cgxD:flex-row">
    <div class="flex w-full flex-col justify-center gap-2 cgxD:gap-4">
      <cgx-headline class="mx-auto cgxD:mx-0" [content]="content().headline"></cgx-headline>

      @if (content().subtitle) {
        <cgx-paragraph
          class="[--paragraph-text-align:center] cgxD:[--paragraph-text-align:left]"
          [content]="content().paragraph"
        ></cgx-paragraph>
      }
    </div>

    <div
      class="min-h-[160px] w-full flex-1 rounded-xl bg-cover p-6 cgxD:h-[248px] cgxD:min-w-[624px]"
      cgxResponsiveBg
      [src]="content().picture"
    ></div>
  </div>
  @if (content().showUspsWithCta) {
    <div class="flex flex-col gap-4 cgxT:gap-6 cgxD:flex-row cgxD:items-center cgxD:justify-between cgxD:gap-16">
      <cgx-usps class="flex-1" [content]="content().usps"></cgx-usps>
      <cgx-cta
        class="cgxT:mx-auto cgxT:[--cta-touch-width:fit-content] cgxD:mx-0 cgxD:[--cta-desktop-width:100%]"
        [content]="content().cta"
      ></cgx-cta>
    </div>
  }
</div>

<ng-template #viewContent>
  <div class="flex flex-col gap-6 rounded-lg bg-white cgxD:w-[376px] cgxD:p-6">
    <div class="flex flex-col items-center gap-2 cgxD:gap-2">
      <cgx-headline class="mx-auto" [content]="content().headline"></cgx-headline>
      @if (content().trustpilot) {
        <cg-customerrating-minimal class="cgxD:pb-2 [&>div]:mt-0"></cg-customerrating-minimal>
      }
      <div class="hidden h-[1px] w-full border border-cg-anthracite-light-80 cgxD:block"></div>

      <!-- Mobile/Touch image -->
      <div
        class="h-[160px] w-full rounded-xl bg-cover bg-center p-6 cgxD:hidden"
        cgxResponsiveBg
        [src]="{ source: content().picture.source, sizes: content().picture.sizes }"
      ></div>
    </div>
    <cgx-usps [content]="content().usps"></cgx-usps>
    <cgx-cta
      class="cgxT:mx-auto cgxT:[--cta-touch-width:fit-content] cgxD:mx-0 cgxD:[--cta-desktop-width:100%]"
      [content]="content().cta"
    >
    </cgx-cta>
  </div>
</ng-template>

<!-- Desktop -->
<div
  class="hidden h-[468px] items-center rounded-xl bg-cover px-12 cgxD:flex"
  cgxResponsiveBg
  [src]="content().picture"
>
  <ng-container [ngTemplateOutlet]="viewContent" />
</div>

<!-- Mobile/Touch -->
<div class="flex flex-col gap-4 rounded-lg bg-white py-6 cgxT:gap-6 cgxD:hidden">
  <ng-container [ngTemplateOutlet]="viewContent" />
</div>
